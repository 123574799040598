export const Depoiments = [
  {
    Depoiment:
      "O pioneiro é um dos auto elétricos mais antigos e tradicionais em sua região.  O lugar é humilde, mas extremamente competente.",
    Nome: "Cristovão Gomes",
  },
  {
    Depoiment:
      "Profissionais competentes e honestos! Fui muito bem atendido pelo Fábio, que demonstrou muito conhecimento e não tentou trocar peças sem necessidade.",
    Nome: "Edmilson santana",
  },
  {
    Depoiment:
      "Cortesia no atendimento e profissionalismo nos serviços executados, preço compatível com o mercado e facilidade no pagamento, o que ajuda muito. Fui atendido pelo Fábio que, desde o primeiro contato foi profissional, educado e conhecedor do ramo.",
    Nome: "Fábio Cristiano",
  },

  {
    Depoiment:
      "Ótimo atendimento, serviço muito bem feito. Recomendo sempre eles.",
    Nome: "Jamison Morais",
  },
  {
    Depoiment:
      "Foi a indicação que recebi e repasso sempre que possível. O dono, seu Luiz, junto com os filhos Alexandre e Fábio são a base do local, mais funcionários.Lá eu levo, explico o problema, deixo se for demorar, busco. Tudo na tranquilidade. Pois são HONESTOS.",

    Nome: "Gerson Santos",
  },
  {
    Depoiment:
      "Ao Sr Luiz, Alexandre e Fábio. Sou amigo deles a 40 anos e cliente dessas pessoas excepcionais a pelo menos 22 anos.  Além de uma família maravilhosa ainda prestam aí na sua Empresa Auto Elétrico Pioneiro um serviço confiável de qualidade e com preço justo! Eu indico, recomendo e ponho a mão no fogo por essas pessoas!",

    Nome: "David Oliveira",
  },
];
