import Router from "./routes";
function App() {
  return (
    <div>
      <Router />;
    </div>
  );
}

export default App;
