import eletrica from "../imgs/cardsServices/elétrica.svg";
import injecaoEletronica from "../imgs/cardsServices/injecaoEletronica.svg";
import mecanica from "../imgs/cardsServices/mecanica.svg";

export const Services = [
  {
    tittle: "ELÉTRICA ",
    img: eletrica,
    link: "/eletrica",
  },
  {
    tittle: "INJEÇÃO ELETRÔNICA",
    img: injecaoEletronica,
    link: "/injecao",
  },
  {
    tittle: "MECÂNICA",
    img: mecanica,
    link: "/mecanica",
  },
];
